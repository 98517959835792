/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:33239c46-8b6a-4a31-b02b-1cd29525bcbe",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_W1fhETOGl",
    "aws_user_pools_web_client_id": "6s6qo2et0kqbpq1ktfaa1g68gs",
    "oauth": {}
};


export default awsmobile;
